<template>
    <div class="loader-container" v-if="showLoader">
      <div class="shooting-star" :class="{ exploded: isExploded }"></div>
      <div class="explosion" :class="{ exploded: isExploded }"></div>
      <div class="split-text" :class="{ exploded: isExploded }">
        <span>L</span><span>C</span> & <span>B</span><span>A</span>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "LoaderComponent",
    data() {
      return {
        isExploded: false,
        showLoader: true,
      };
    },
    mounted() {
      // Trigger explosion after shooting star reaches center
      setTimeout(() => {
        this.isExploded = true;
      }, 3000); // Adjust the timing based on the shooting star animation
  
      // Hide the loader after an additional delay
      setTimeout(() => {
        this.hideLoader();
      }, 8000); // 4 seconds delay after explosion to hide the loader
    },
    methods: {
      hideLoader() {
        this.showLoader = false;
      },
    },
  };
  </script>
  
  <style scoped>
  .loader-container {
    position: relative;
    width: 100vw;
    height: 100vh;
    background-color: #000; /* Dark background for high contrast */
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .shooting-star {
    position: absolute;
    top: 50%;
    left: -150px;
    width: 30px;
    height: 30px;
    background: radial-gradient(circle, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0) 70%);
    border-radius: 50%;
    box-shadow: 0 0 80px 30px rgba(255, 255, 255, 0.8);
    transform: translateZ(0) perspective(1000px) rotateX(0deg) rotateY(0deg);
    animation: shooting-star-move 3s cubic-bezier(0.25, 0.1, 0.25, 1) forwards, shooting-star-glow 1.5s ease-in-out infinite;
  }
  
  @keyframes shooting-star-move {
    0% {
      left: -150px;
      opacity: 1;
      transform: translateZ(0) perspective(1000px) rotateX(0deg) rotateY(0deg);
    }
    50% {
      left: 50vw;
      opacity: 1;
      transform: translateZ(0) perspective(1000px) rotateX(10deg) rotateY(10deg);
    }
    100% {
      left: 50vw;
      opacity: 0;
      transform: translateZ(-300px) perspective(1000px) rotateX(60deg) rotateY(60deg);
    }
  }
  
  @keyframes shooting-star-glow {
    0%, 100% {
      box-shadow: 0 0 80px 30px rgba(255, 255, 255, 0.8);
    }
    50% {
      box-shadow: 0 0 150px 50px rgba(255, 255, 255, 0.6);
    }
  }
  
  .explosion {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 300px;
    height: 300px;
    border-radius: 50%;
    background: radial-gradient(circle, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0) 100%);
    transform: scale(0);
    transition: transform 1s cubic-bezier(0.68, -0.55, 0.27, 1.55), opacity 1s ease-in-out;
    z-index: 1;
  }
  
  .explosion.exploded {
    transform: scale(6);
    opacity: 0;
    background: radial-gradient(circle, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0) 100%);
  }
  
  .split-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 5rem;
    color: #fff;
    opacity: 0;
    display: flex;
    gap: 1rem;
    transition: opacity 0.6s ease, transform 0.8s ease;
    z-index: 2;
  }
  
  .split-text span {
    display: inline-block;
    opacity: 0;
    transform: translateY(100px) scale(1.5);
    transition: transform 0.8s ease, opacity 0.8s ease;
  }
  
  .split-text.exploded {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
  
  .split-text.exploded span {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
  
  .split-text.exploded span:nth-child(1) {
    transition-delay: 0.2s;
    transform: translateY(-50px) scale(1.2);
  }
  
  .split-text.exploded span:nth-child(2) {
    transition-delay: 0.3s;
    transform: translateY(50px) scale(1.2);
  }
  
  .split-text.exploded span:nth-child(3) {
    transition-delay: 0.4s;
    transform: translateY(-50px) scale(1.2);
  }
  
  .split-text.exploded span:nth-child(4) {
    transition-delay: 0.5s;
    transform: translateY(50px) scale(1.2);
  }
  </style>
  