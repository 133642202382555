<template>
  <div>
    <LoaderComponent v-if="isLoading" />
    <router-view v-else />
    <!-- Contact Us Button -->
    <div id="contact-button" @click="navigateToContact">
      <span>
        <font-awesome-icon :icon="['fas', 'envelope']" class="mail-icon" />
        <span class="text-2">Contact Us</span>
      </span>
      <div class="liquid"></div>
    </div>
  </div>
</template>

<script>
import LoaderComponent from "@/components/LoaderComponent.vue"; // Adjust the path if necessary

export default {
  name: 'App',
  components: {
    LoaderComponent, // Register the component
  },
  data() {
    return {
      isLoading: false, // Define isLoading here
    };
  },
  methods: {
    navigateToContact() {
      this.$router.push('/contact');
    },
    showLoader() {
      this.isLoading = true;
    },
    hideLoader() {
      setTimeout(() => {
        this.isLoading = false;
      }, 4000); // Adjust the duration to match your loader animation
    },
  },
  mounted() {
    this.$router.beforeEach((_, __, next) => {
      this.showLoader();
      next();
    });

    this.$router.afterEach(() => {
      this.hideLoader();
    });
  },
};
</script>

<style>
@font-face {
  font-family: "Ubuntu";
  font-family: "Merriweather";
  font-family: "SourceSans3";
  font-family: "OpenSans";
  /* src: url('@/assets/fonts/static/SourceSans3-Medium.ttf') format('truetype'); */
  src: url('@/assets/fonts/static/SourceSans3-Medium.ttf') format('truetype');
  src: url('@/assets/fonts/Open_Sans/static/OpenSans-Light.ttf') format('truetype');
  src: url('@/assets/fonts/Ubuntu/static/Ubuntu-Regular.ttf') format('truetype');
}

#contact-button {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 1000;
  display: inline-block;
  background: var(--primary-color);
  color: var(--white-color);
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(30, 144, 255, 0.5);
  transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1), background 0.6s, transform 0.3s ease;
  text-align: center;
  width: 33px;
  height: 33px;
}

#contact-button span {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.mail-icon {
  margin-right: -75px;
  font-size: 20px; /* Icon visible initially */
  transition: opacity 0.6s ease;
}

.text-2 {
  margin-left: 4px; /* Adjust margin to fit the small size */
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.6s ease, visibility 0s;
  white-space: nowrap;
  font-size: 12px;
  left: 25px; /* Adjusted font size for the text */
}
 #contact-button:hover .mail-icon {
  opacity: 0;
  visibility: hidden;
} 

#contact-button:hover .text-2 {
  opacity: 1;
  visibility: visible;
}

#contact-button:hover {
  background: var(--primary-color);
  box-shadow: 0 15px 50px rgba(30, 144, 255, 0.7);
  width: 200px;
}

.liquid {
  position: absolute;
  top: -100%;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.3);
  animation: cascade 2s infinite;
}

@keyframes cascade {
  0% {
    top: -100%;
  }
  50% {
    top: 0;
  }
  100% {
    top: 100%;
  }
}

#contact-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  box-shadow: 0 0 20px rgba(30, 144, 255, 0.8),
    0 0 40px rgba(30, 144, 255, 0.6),
    0 0 60px rgba(30, 144, 255, 0.4);
  opacity: 0;
  transition: opacity 0.6s ease;
}

#contact-button:hover::before {
  opacity: 1;
}

@media screen and (max-width: 480px) {
  #contact-button {
    padding: 0;
    bottom: 20px;
    right: 20px;
    font-size: 16px;
    width: 33px;
    height: 33px;
  }

  #contact-button:hover {
    width: 160px;
  }
}
</style>
